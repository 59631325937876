import React from "react"
import { Link } from "gatsby"

import MTwebHeader from '../assets/MT-web-header.png';
import MTknowledge from '../assets/MT-web-knowledge.png';
import MTbusiness1 from '../assets/businessCard-front.svg';
import MTbusiness2 from '../assets/businessCard-back.svg';
import pas from '../assets/PAS-desktop.jpg';
import MTwebBottom from '../assets/MT-web-bottom.jpg';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img className="col-7 margin-btm-sm mobile-col-10" src={MTwebHeader} alt="Two screens of the Mariana Tek website" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs col-7 col-100">Mariana Tek - Marketing Materials</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>I redesigned most of Mariana Tek’s marketing materials, including their website. Mariana was at a very large disadvantage when I joined. They had a website that simply featured a picture of a woman's 6 pack, their logo didn’t and still doesn’t say much about what Mariana actually does and their other marketing materials were poor, as they didn't have the design resources to build them. After doing competitive research, interviews, stakeholder interviews and holding a kickoff meeting with stakeholders, to build agreement on a direction, I was able to redesign their website, make business cards, decks, etc… so that Mariana could put a much better foot forward in the marketplace.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Director of Design</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Design / UX</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Competitive Analysis</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Stakeholder Meetings</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Moodboards</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Direction and Coordination</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Some HTML & CSS</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding flex flex-center col-100 row-padding-md mobile-margin-btm-sm">
              <h3 className="header--small">Brand Exploration</h3>
              <p>What I learned after leading a discovery meeting and doing some research, was that clients came to Mariana mostly because of their industry knowledge, their empathy and their willingness to work with clients to help them succeed. Having a stock picture of a woman's ab muscles certainly didn’t communicate this.</p>

              <p>We needed something that was simple and clean. A good foundation.</p>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="flex flex-grid flex-grid-business">
                <div className="project">
                  <img className="withShadow-lg project-with-border" src={MTbusiness1} alt="Front of Mariana Tek's business card" />
                </div>
                <div className="project">
                  <img className="withShadow-lg project-with-border" src={MTbusiness2} alt="Back of Mariana Tek's business card" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth process-bg">
        <div className="section-internal">
          <div className="align-center margin-btm-md">
            <h3 className="header--small">Process</h3>
          </div>
          <div className="flex-grid-5">
            <div>
              <h4 className="header--smallest">Discovery</h4>
              <p>I gathered information about our current brand and what relevant people in the industry and stakeholders thought about Mariana.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Competitive Analysis / Research</h4>
              <p>I set out to understand: who our competitors were, their brand voice, why studios choose them and how Mariana Tek could differentiate themselves based on that information.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Moodboards / Example Comps</h4>
              <p>Before designing anything in full, I designed small sections of pages and created moodboards to build consensus on where we were going and how that translated into design.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Design</h4>
              <p>I started off building out the design of the website first, being one of the things Mariana desperately needed at the time. Taking the tone / design I had come up with from the site, I then translated it to the other marketing materials.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Building / Printing etc...</h4>
              <p>For the website, I built part of the site in HTML/CSS as there was a lack of resources. For the business cards, for example, I coordinated printing etc... to get them into the hands of our staff.</p>
              <span className="text-light">Myself</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-8 col-100">
              <div className="project project-neg-left row-padding-md row-padding-md-mob-none mobile-img-center">
                <img src={MTknowledge} alt="Two pages from the Mariana Tek website" />
              </div>
            </div>
            <div className="column column2 col-4 row-padding flex flex-center col-100">
              <h3 className="header--small">Communicating Mariana Tek's Knowledge </h3>
              <p>To show clients that Mariana understood the market and that the suite of products would be a good fit for them, I added product images throughout the site, listed features so that clients could better understand the quality of the products they would be getting, and featured well-known clients we worked with to build confidence.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding flex flex-center col-100 row-padding-md row-padding-md-mob-none">
              <div className="margin-btm-sm">
                <h3 className="header--small">Empathy</h3>
                <p>To better communicate Mariana's empathy and willingness to work with clients, I added a sense of warmth to the site. Alongside the product images, I added pictures of people smiling / interacting. I transitioned the primary dark purple color Mariana was using by lightening and brightening it. And I added pictures of Mariana's staff, so that our clients could not only understand who we were but see it as well.</p>
              </div>
              <div className="project mobile-img-center">
                <img className="withShadow-lg project-with-border" src={pas} alt="Webpage for Mariana Tek explaining Pick-a-spot" />
              </div>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="projec mobile-img-center">
                <img className="withShadow-lg project-with-border" src={MTwebBottom} alt="Parital page from Mariana Tek website, showing picture of some staff members, along with product shots" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-MTWI">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link className="footer-link " to="/MT-web-integrations/">
                  <h3 className="header--medium">Mariana Tek - Web Integrations</h3>
                </Link>
                <span className="description">Design, UX, Prototyping, & Testing</span>
              </div>
              <Link className="footer-link " to="/MT-web-integrations/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
